<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getItems()" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
      <wiki slug="services-voip-overview" />
    </v-toolbar>

    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        sort-by="subscription_id"
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        local-storage-name="voipList"
      >
        <template v-slot:top>
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'voip-service-details',
                    params: { productId: item.product_id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("open VoIP subscription details") }}</span>
          </v-tooltip>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended";
import TelephonyVoipDetails from "@/views/services/TelephonyVoipDetails";
import formatDate from "@/utils/mixins/formatDate";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "TelephonyVoip",
  mixins: [formatDate, isMobile],
  components: {
    DataTableExtended,
    TelephonyVoipDetails,
    Wiki,
  },
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    expanded: [],
    create: false,
    items: [],
    itemsTotal: 0,
    options: {},
    search: "",
  }),
  computed: {
    headers() {
      return [
        { text: this.$i18n.t("product name"), value: "product_name" },
        { text: this.$i18n.t("description"), value: "product_description" },
        {
          text: this.$i18n.t("subscription number"),
          value: "subscription_id",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
  },
  methods: {
    customFilter(value, search, item) {
      return (
        (item.subscription_id != null &&
          item.subscription_id.indexOf(search) >= 0) ||
        (item.product_name != null && item.product_name.indexOf(search) >= 0) ||
        (item.product_descr != null && item.product_descr.indexOf(search) >= 0)
      );
    },
    getItems() {
      var that = this;
      that.loading = true;
      this.$http
        .get("services/voip", {
          params: { product_id: this.productId },
        })
        .then((response) => {
          that.items = response.data;
          that.itemsTotal = response.data.length;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "product name": "Product Name",
    "description": "Description",
    "subscription number": "Subscription Number",
    "reload": "reload",
    "open VoIP subscription details": "open VoIP subscription details"
  },
  "de": {
    "product name": "Produkt Name",
    "description": "Beschreibung",
    "subscription number": "Abonnement Nummer",
    "reload": "aktualisieren",
    "open VoIP subscription details": "VoIP Abonnement Details öffnen"
  }
}
</i18n>
