<template>
  <v-card disabled flat>
    <v-simple-table dense class="pb-2 mt-4">
      <tbody>
        <tr>
          <th>{{ $t("name") }}</th>
          <td>
            {{ item.name ? item.name : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("type") }}</th>
          <td>
            {{ item.type ? item.type : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("priority") }}</th>
          <td>
            {{ item.priority ? item.priority : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("dest pattern") }}</th>
          <td>
            {{ item.dest_pattern ? item.dest_pattern : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("source_pattern") }}</th>
          <td>
            {{ item.source_pattern ? item.source_pattern : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("source_presentation_pattern") }}</th>
          <td>
            {{
              item.source_presentation_pattern
                ? item.source_presentation_pattern
                : "-"
            }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("dest_replace") }}</th>
          <td>
            {{ item.dest_replace ? item.dest_replace : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("time_pattern") }}</th>
          <td>
            {{ item.time_pattern ? item.time_pattern : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("reject_pattern") }}</th>
          <td>
            {{ item.reject_pattern ? item.reject_pattern : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("delay") }}</th>
          <td>
            {{ item.delay ? item.delay : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("propagate busy") }}</th>
          <td>
            <boolean-value :value="item.propagate_busy" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("parallel call") }}</th>
          <td>
            <boolean-value :value="item.parallel_call" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("always ring") }}</th>
          <td>
            <boolean-value :value="item.always_ring" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("early media") }}</th>
          <td>
            <boolean-value :value="item.early_media" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("last diversion") }}</th>
          <td>
            <boolean-value :value="item.last_diversion" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("reroute") }}</th>
          <td>
            <boolean-value :value="item.reroute" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("suspended") }}</th>
          <td>
            <boolean-value :value="item.suspended" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("disabled") }}</th>
          <td>
            <boolean-value :value="item.disabled" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("start date") }}</th>
          <td>
            {{ item.start_date ? formatDate(item.start_date) : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("end date") }}</th>
          <td>
            {{ item.end_date ? formatDate(item.end_date) : "-" }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";

export default {
  name: "TopStop",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: { BooleanValue },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        }
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "name": "Name",
    "type": "Type",
    "priority": "Priority",
    "dest pattern": "Dest pattern",
    "source pattern": "Source pattern",
    "source presentation pattern": "Source presentation pattern",
    "dest replace": "Dest replace",
    "time pattern": "Time pattern",
    "reject pattern": "Reject pattern",
    "delay": "Delay",
    "propagate busy": "Propagate busy",
    "parallel call": "Parallel call",
    "always ring": "Always ring",
    "early media": "Early media",
    "last diversion": "Last diversion",
    "reroute": "Reroute",
    "suspended": "Suspended",
    "disabled": "Disabled",
    "start date": "Start date",
    "end date": "End date"
  },
  "de": {
"name": "Name",
    "type": "Type",
    "priority": "Priority",
    "dest pattern": "Dest pattern",
    "source pattern": "Source pattern",
    "source presentation pattern": "Source presentation pattern",
    "dest replace": "Dest replace",
    "time pattern": "Time pattern",
    "reject pattern": "Reject pattern",
    "delay": "Delay",
    "propagate busy": "Propagate busy",
    "parallel call": "Parallel call",
    "always ring": "Always ring",
    "early media": "Early media",
    "last diversion": "Last diversion",
    "reroute": "Reroute",
    "suspended": "Suspended",
    "disabled": "Disabled",
    "start date": "Start date",
    "end date": "End date"
  }
}
</i18n>
