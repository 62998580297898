var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){return _vm.getItems()}}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("reload")))])]),_c('wiki',{attrs:{"slug":"services-voip-overview"}})],1),_c('v-card-text',[_c('data-table-extended',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"search":_vm.search,"custom-filter":_vm.customFilter,"sort-by":"subscription_id","hide-default-footer":_vm.items.length <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      },"local-storage-name":"voipList"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.action",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'voip-service-details',
                  params: { productId: item.product_id },
                })}}},on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("open VoIP subscription details")))])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }