<template>
  <v-card class="my-4" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("VoIP subscription") }}
      </v-toolbar-title>
      <v-spacer />

      <v-tooltip top v-if="item != null">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            :loading="loading"
            v-on="on"
            @click="getItem()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="item != null && item.product_id">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: productId },
              })
            "
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("jump to product") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-progress-linear :active="loading" />

    <v-card-text>
      <v-card v-if="item && item.subscription" class="mb-4" outlined>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("subscription") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="ma-4">
          <tbody>
            <tr>
              <th>{{ $t("subscription number") }}</th>
              <td>{{ item.subscription.id }}</td>
            </tr>

            <tr>
              <th>{{ $t("subscription name") }}</th>
              <td>{{ item.subscription.name }}</td>
            </tr>

            <tr>
              <th>{{ $t("description") }}</th>
              <td>{{ item.subscription.description || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("customer reference") }}</th>
              <td>{{ item.subscription.customer_reference || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("product option") }}</th>
              <td>{{ item.subscription.product_option || "-" }}</td>
            </tr>
            <!--
            <tr>
              <th>{{ $t("type") }}</th>
              <td>{{ item.subscription.type || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("status") }}</th>
              <td>{{ item.subscription.status || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("system") }}</th>
              <td>{{ item.subscription.system || "-" }}</td>
            </tr>
            -->
            <tr>
              <th>{{ $t("channels") }}</th>
              <td>
                {{
                  item.subscription.channels != null
                    ? item.subscription.channels
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("start date") }}</th>
              <td>
                {{
                  item.subscription.start_date
                    ? formatDate(item.subscription.start_date)
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("end date") }}</th>
              <td>
                {{
                  item.subscription.end_date
                    ? formatDate(item.subscription.end_date)
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="item && item.subscription && item.subscription.end_user"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("end user") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("company name") }}</th>
              <td>
                {{
                  item.subscription.end_user.company_name != null
                    ? item.subscription.end_user.company_name
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("first name") }}</th>
              <td>
                {{
                  item.subscription.end_user.first_name != null
                    ? item.subscription.end_user.first_name
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("last name") }}</th>
              <td>
                {{
                  item.subscription.end_user.last_name != null
                    ? item.subscription.end_user.last_name
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("email") }}</th>
              <td>
                {{
                  item.subscription.end_user.email != null
                    ? item.subscription.end_user.email
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("username") }}</th>
              <td>
                {{
                  item.subscription.end_user.username != null
                    ? item.subscription.end_user.username
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="
          item &&
          item.subscription &&
          (item.subscription.monthly_costs != null ||
            item.subscription.setup_costs != null)
        "
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("costs") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("monthly costs") }}</th>
              <td>
                {{
                  item.subscription.monthly_costs != null
                    ? formatMoney(item.subscription.monthly_costs)
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("setup costs") }}</th>
              <td>
                {{
                  item.subscription.monthly_costs != null
                    ? formatMoney(item.subscription.setup_costs)
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="
          item &&
          item.subscription &&
          (item.subscription.teams_name != null ||
            item.subscription.teams_value != null)
        "
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("MS teams") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("teams name") }}</th>
              <td>{{ item.subscription.teams_name || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("teams value") }}</th>
              <td>{{ item.subscription.teams_value || "-" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="item && item.subscription && item.subscription.top_stop"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("top stop") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <top-stop :value="item.subscription.top_stop" />
        <v-simple-table dense class="pb-2 mt-4" />
      </v-card>

      <v-card
        v-if="item && item.subscription && item.subscription.vpbx_id != null"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("vpbx") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("vpbx id") }}</th>
              <td>{{ item.subscription.vpbx_id || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("vpbx name") }}</th>
              <td>{{ item.subscription.vpbx_name || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("vpbx firmware") }}</th>
              <td>{{ item.subscription.vpbx_firmware || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("vpbx max cli") }}</th>
              <td>
                {{
                  item.subscription.vpbx_max_cli != null
                    ? item.subscription.vpbx_max_cli
                    : "-"
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t("vpbx max user") }}</th>
              <td>
                {{
                  item.subscription.vpbx_max_user != null
                    ? item.subscription.vpbx_max_user
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <template v-if="item.subscription.vpbx_domain">
          <v-card-title>{{ $t("vpbx domain") }}</v-card-title>
          <v-simple-table dense class="pb-2 mt-4">
            <tbody>
              <tr>
                <th>{{ $t("domain") }}</th>
                <td>
                  {{ item.subscription.vpbx_domain.domain || "-" }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("priority") }}</th>
                <td>
                  {{
                    item.subscription.vpbx_domain.priority != null
                      ? item.subscription.vpbx_domain.priority
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>

        <template v-if="item.subscription.vpbx_config">
          <v-card-title>{{ $t("vpbx config") }}</v-card-title>
          <v-simple-table dense class="pb-2 mt-4">
            <tbody>
              <tr>
                <th>{{ $t("ACL import export") }}</th>
                <td>
                  <boolean-value
                    :value="item.subscription.vpbx_config.acl_import_export"
                  />
                </td>
              </tr>

              <tr>
                <th>{{ $t("ACL reboot") }}</th>
                <td>
                  <boolean-value
                    :value="item.subscription.vpbx_config.acl_reboot"
                  />
                </td>
              </tr>

              <tr>
                <th>{{ $t("ACL traces") }}</th>
                <td>
                  <boolean-value
                    :value="item.subscription.vpbx_config.acl_traces"
                  />
                </td>
              </tr>

              <tr>
                <th>{{ $t("user") }}</th>
                <td>{{ item.subscription.vpbx_config.user }}</td>
              </tr>

              <tr>
                <th>{{ $t("password") }}</th>
                <td>{{ item.subscription.vpbx_config.password }}</td>
              </tr>

              <tr>
                <th>{{ $t("terminal access") }}</th>
                <td>{{ item.subscription.vpbx_config.terminal_access }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
      </v-card>

      <v-card
        v-if="item && item.subscription && item.subscription.call_forwards"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("call forwards") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(cf, key) in item.subscription.call_forwards"
            :key="key"
          >
            <v-expansion-panel-header>
              <b>{{ cf.name }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <call-forward :value="cf" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>

      <v-card
        v-if="item && item.subscription && item.subscription.call_forwards"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("numbers") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-expansion-panels>
          <v-expansion-panel v-for="(num, key) in numbers" :key="key">
            <v-expansion-panel-header>
              <b>{{ num.number }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <number :value="num" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";
import TopStop from "@/components/services/voip/TopStop";
import CallForward from "@/components/services/voip/CallForward";
import EmergencyContact from "@/components/services/voip/EmergencyContact";
import Number from "@/components/services/voip/Number";

export default {
  name: "TelephonyVoipDetails",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
    TopStop,
    CallForward,
    EmergencyContact,
    Number,
  },
  props: {
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data: () => ({
    item: null,
    loadingItem: false,
    loadingNumbers: false,
    numbers: [],
  }),
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getItem();
          this.getNumbers();
        }
      },
    },
  },
  computed: {
    loading() {
      return this.loadingItem || this.loadingNumbers;
    },
  },
  methods: {
    getItem() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get("services/voip/" + this.productId)
        .then((response) => {
          that.item = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    getNumbers() {
      var that = this;
      that.loadingNumbers = true;
      this.$http
        .get("services/voip/" + this.productId + "/numbers")
        .then((response) => {
          that.numbers = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingNumbers = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "VoIP details": "VoIP Details",
    "VoIP subscription": "VoIP Subscription",
    "reload": "reload",
    "jump to product": "jump to product",
    "subscription": "Subscription",
    "subscription number": "Subscription number",
    "subscription name": "Subscription name",
    "customer reference": "Customer reference",
    "product option": "Product option",
    "type": "Type",
    "status": "Status",
    "system": "System",
    "channels": "Channels",
    "start date": "Start date",
    "end date": "End date",
    "company name": "Company name",
    "first name": "First name",
    "last name": "Last name",
    "email": "Email",
    "username": "Username",
    "costs": "Costs",
    "monthly costs": "Monthly costs",
    "setup costs": "Setup costs",
    "MS teams": "MS teams",
    "teams name": "Teams name",
    "teams value": "Teams value",
    "top stop": "Top stop",
    "vpbx": "VPBX",
    "vpbx id": "VPBX ID",
    "vpbx name": "VPBX name",
    "vpbx firmware": "VPBX firmware",
    "vpbx max cli": "VPBX max. CLI",
    "vpbx max user": "VPBX max. user",
    "vpbx domain": "VPBX domain",
    "domain": "Domain",
    "priority": "Priority",
    "vpbx config": "VPBX config",
    "ACL import export": "ACL import export",
    "ACL reboot":"ACL reboot",
    "ACL traces": "ACL traces",
    "user": "User",
    "password": "Password",
    "terminal access": "Terminal access",
    "call forwards": "Call forwards",
    "numbers": "Numbers",
    "end user": "End user"
  },
  "de": {
    "VoIP details": "VoIP Details",
    "VoIP subscription": "VoIP Abonnement",
    "reload": "aktualisieren",
    "jump to product": "zum Produkt wechseln",
    "subscription": "Subscription",
    "subscription number": "Subscription number",
    "subscription name": "Subscription name",
    "customer reference": "Customer reference",
    "product option": "Product option",
    "type": "Type",
    "status": "Status",
    "system": "System",
    "channels": "Channels",
    "start date": "Start date",
    "end date": "End date",
    "company name": "Company name",
    "first name": "First name",
    "last name": "Last name",
    "email": "Email",
    "username": "Username",
    "costs": "Costs",
    "monthly costs": "Monthly costs",
    "setup costs": "Setup costs",
    "MS teams": "MS teams",
    "teams name": "Teams name",
    "teams value": "Teams value",
    "top stop": "Top stop",
    "vpbx": "VPBX",
    "vpbx id": "VPBX ID",
    "vpbx name": "VPBX name",
    "vpbx firmware": "VPBX firmware",
    "vpbx max cli": "VPBX max. CLI",
    "vpbx max user": "VPBX max. user",
    "vpbx domain": "VPBX domain",
    "domain": "Domain",
    "priority": "Priority",
    "vpbx config": "VPBX config",
    "ACL import export": "ACL import export",
    "ACL reboot":"ACL reboot",
    "ACL traces": "ACL traces",
    "user": "User",
    "password": "Password",
    "terminal access": "Terminal access",
    "call forwards": "Call forwards",
    "numbers": "Numbers",
    "end user": "End user"
  }
}
</i18n>
