<template>
  <v-card disabled flat>
    <v-simple-table dense class="pb-2 mt-4">
      <tbody>
        <tr>
          <th>{{ $t("address") }}</th>
          <td>
            {{ item.address ? item.address : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("longitude") }}</th>
          <td>
            {{ item.longitude ? item.longitude : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("latitude") }}</th>
          <td>
            {{ item.latitude ? item.latitude : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("created at") }}</th>
          <td>
            {{ item.created_at ? formatDate(item.created_at) : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("created by") }}</th>
          <td>
            {{ item.created_by ? item.created_by : "-" }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";

export default {
  name: "EmergencyContact",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        }
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "address": "Address",
    "longitude": "Longitude",
    "latitude": "Latitude",
    "created at": "Created at",
    "created by": "Created by"
  },
  "de": {
    "address": "Address",
    "longitude": "Longitude",
    "latitude": "Latitude",
    "created at": "Created at",
    "created by": "Created by"
  }
}
</i18n>
