<template>
  <v-card disabled flat>
    <v-simple-table dense>
      <tbody>
        <tr>
          <th>{{ $t("national number") }}</th>
          <td>{{ item.national_number }}</td>
        </tr>
        <tr>
          <th>{{ $t("display name") }}</th>
          <td>
            {{ item.display_name != null ? item.display_name : "-" }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("is main") }}</th>
          <td>
            <boolean-value :value="item.is_main" />
          </td>
        </tr>
        <tr>
          <th>{{ $t("language") }}</th>
          <td>{{ item.language != null ? item.language : "-" }}</td>
        </tr>
        <tr>
          <th>{{ $t("signalling only") }}</th>
          <td>
            <boolean-value :value="item.signalling_only" />
          </td>
        </tr>
        <tr>
          <th>{{ $t("premium number") }}</th>
          <td>
            <boolean-value :value="item.premium_number" />
          </td>
        </tr>
        <tr>
          <th>{{ $t("premium number class") }}</th>
          <td>
            {{
              item.premium_number_class != null
                ? item.premium_number_class
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("block size") }}</th>
          <td>{{ item.block_size }}</td>
        </tr>
        <tr>
          <th>{{ $t("start date") }}</th>
          <td>
            {{ item.start_date != null ? formatDate(item.start_date) : "-" }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("end date") }}</th>
          <td>
            {{ item.end_date != null ? formatDate(item.end_date) : "-" }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-card-title>{{ $t("top stop") }}</v-card-title>
    <top-stop :value="item.top_stop || {}" />

    <v-card-title>{{ $t("emergency contact") }}</v-card-title>
    <emergency-contact :value="item.emergency_contact || {}" />

    <template>
      <v-card-title>{{ $t("SIP registrations") }}</v-card-title>
      <v-card
        v-for="(reg, key) in item.sip_registrations"
        :key="key"
        disabled
        flat
      >
        <sip-registration :value="reg" />
      </v-card>
    </template>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";
import TopStop from "@/components/services/voip/TopStop";
import CallForward from "@/components/services/voip/CallForward";
import EmergencyContact from "@/components/services/voip/EmergencyContact";
import SipRegistration from "@/components/services/voip/SipRegistration";

export default {
  name: "Number",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
    TopStop,
    CallForward,
    EmergencyContact,
    SipRegistration,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        }
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "national number": "National number",
    "display name": "Display name",
    "is main": "Is main",
    "language": "Language",
    "signalling only": "Signalling only",
    "premium number": "Premium number",
    "premium number class": "Premium number class",
    "block size": "Block size",
    "start date": "Start date",
    "end date": "End date",
    "top stop": "Top stop",
    "emergency contact": "Emergency contact",
    "SIP registrations": "SIP registrations"
  },
  "de": {
    "national number": "National number",
    "display name": "Display name",
    "is main": "Is main",
    "language": "Language",
    "signalling only": "Signalling only",
    "premium number": "Premium number",
    "premium number class": "Premium number class",
    "block size": "Block size",
    "start date": "Start date",
    "end date": "End date",
    "top stop": "Top stop",
    "emergency contact": "Emergency contact",
    "SIP registrations": "SIP registrations"
  }
}
</i18n>
